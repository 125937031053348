<template>
    <section>
        <modal ref="editarDatos" v-loading="loading" :element-loading-text="`${carga}%`" titulo="Editar datos de la tienda" icon="online-shop" tamano="modal-lg" @guardar="updateDatos">
            <ValidationObserver ref="validacion">
                <div class="row mx-3 f-15">
                    <div class="col-5 text-center">
                        <p>Logo o foto de la tienda</p>
                        <div class="row justify-center">
                            <slim-cropper ref="cropLogo" :options="slimOptions" class="border" style="height:160px;width:160px;background:#F8F9FF;">
                                <!-- <img :src="formulario.imagen | helper-storage()" alt="" /> -->
                                <div slot="label" class="">
                                    <img src="/img/modales/camera.svg" alt="" />
                                </div>
                            <!-- <input id="imagen" type="file" name="imagen" accept="image/*" /> -->
                            </slim-cropper>
                        </div>
                    </div>
                    <div class="col-7 overflow-auto custom-scroll" style="max-height:40vh;">
                        <div class="my-3">
                            <ValidationProvider v-slot="{errors}" rules="required|max:60" name="nombre tienda">
                                <p class="ml-2">Nombre de la tienda</p>
                                <el-input v-model="model.nombre" maxlength="60" show-word-limit />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="my-3">
                            <ValidationProvider v-slot="{errors}" rules="required|max:60" name="nombre dueño">
                                <p class="ml-2">Nombre del dueño</p>
                                <el-input v-model="model.propietario_nombre" maxlength="60" show-word-limit />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <!-- <div class="my-3">
                            <ValidationProvider v-slot="{errors}" rules="required|max:10|numeric" name="cédula">
                                <p class="ml-2">Cédula de Ciudadanía</p>
                                <el-input v-model="model.propietario_identificacion" maxlength="10" show-word-limit />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div> -->
                        <div class="my-3">
                            <ValidationProvider v-slot="{errors}" rules="required" name="país">
                                <p class="ml-2">País</p>
                                <el-select v-model="model.idm_pais" class="w-100" :disabled="disabled" filterable>
                                    <el-option
                                    v-for="item in paises"
                                    :key="item.id"
                                    :label="item.nombre"
                                    :value="item.id"
                                    />
                                </el-select>
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="my-3">
                            <ValidationProvider v-slot="{errors}" rules="required" name="estado">
                                <p class="ml-2">Estado</p>
                                <el-select v-model="model.idm_estado" class="w-100" :disabled="disabled" filterable>
                                    <el-option
                                    v-for="item in estados"
                                    :key="item.id"
                                    :label="item.nombre"
                                    :value="item.id"
                                    />
                                </el-select>
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="my-3">
                            <ValidationProvider v-slot="{errors}" rules="required" name="ciudad">
                                <p class="ml-2">Ciudad</p>
                                <div class="row mx-0">
                                    <el-select v-model="model.idm_ciudad" class="col" :disabled="disabled" filterable>
                                        <el-option
                                        v-for="item in ciudades"
                                        :key="item.id"
                                        :label="item.nombre"
                                        :value="item.id"
                                        />
                                    </el-select>
                                    <i class="icon-pencil f-22 cr-pointer" @click="disabled = !disabled" />
                                </div>
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="my-3">
                            <ValidationProvider v-slot="{errors}" rules="required|numeric|max:10" name="celular">
                                <p class="ml-2">Número celular</p>
                                <el-input v-model="model.telefono_celular" maxlength="10" show-word-limit />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="my-3">
                            <ValidationProvider v-slot="{errors}" rules="required|max:70" name="dirección">
                                <p class="ml-2">Dirección</p>
                                <el-input v-model="model.direccion" maxlength="70" show-word-limit />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="my-3">
                            <ValidationProvider v-slot="{errors}" rules="max:40" name="complemento">
                                <p class="ml-2">Complemento (Opcional)</p>
                                <el-input v-model="model.direccion_anexo" maxlength="40" show-word-limit />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="my-3">
                            <img :src="rutaImagenMapa()" class="w-100 cr-pointer" @click="abrirModalMapa" />
                            <!-- <p class="f-13 mt-2 text-gris2">
                                Haga click aquí, para seleccionar la ubicación de su tienda
                            </p> -->
                        </div>
                        <div class="my-3">
                            <ValidationProvider v-slot="{errors}" rules="required|max:30" name="barrio">
                                <p class="ml-2">Barrio </p>
                                <el-input v-model="model.barrio" maxlength="30" show-word-limit />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="my-3">
                            <ValidationProvider v-slot="{errors}" rules="required|max:250" name="descripción">
                                <p class="ml-2">Descripción</p>
                                <el-input v-model="model.descripcion" type="textarea" :rows="3" maxlength="250" show-word-limit />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="my-3">
                            <ValidationProvider v-slot="{errors}" rules="numeric|max:9" name="NIT">
                                <p class="ml-2">Nit de la tienda (Opcional)</p>
                                <el-input v-model="model.nit" maxlength="9" show-word-limit />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="my-3">
                            <ValidationProvider v-slot="{errors}" rules="" name="soporte">
                                <p class="ml-2">Soporte (Opcional)</p>
                                <el-upload
                                ref="adjuntos"
                                class="upload-demo"
                                action="#"
                                :multiple="false"
                                :limit="1"
                                :on-change="agregar_archivos"
                                :auto-upload="false"
                                :on-remove="handleRemove"
                                :file-list="fileList"
                                >
                                    <button type="button" class="btn w-100 br-10 border bg-whitesmoke">
                                        Cargar soporte
                                    </button>
                                </el-upload>
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
            </ValidationObserver>
        </modal>
        <mapa-google-modal
        ref="modalMapaGoogle"
        :coordenadas-entrada="coordenadas"
        @actualizar="actualizarCoordenadasCiudad"
        />
    </section>
</template>

<script>
import DatosTienda from "../../../../services/configurar/tendero-vip/datos_tienda";
import Localizaciones from "../../../../services/proyectos/localizaciones";
import Aws from '~/services/aws'

export default {
    data(){
        return {
            disabled: true,
            paises: [],
            estados: [],
            ciudades: [],
            tipos_tiendas: [],
            slimOptions: {
                // ratio: '5:2',
                size: { width: 250, height: 250 },
                minSize: { width: 50, height: 50 },
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok'
            },
            model: {
                barrio:null,
                descripcion:null,
                direccion:null,
                direccion_anexo:null,
                estado:null,
                id:null,
                idm_ciudad:null,
                idm_estado:null,
                idm_pais:null,
                latitud:0,
                logo:null,
                logo_mini:null,
                longitud:0,
                nit:null,
                nit_soporte:null,
                nombre:null,
                propietario_nombre:null,
                telefono_celular:null,
            },
            coordenadas: {
                lat: 0,
                lng: 0,
            },
            archivo: [],
            fileList: [],
            loading: false,
            carga: 0,
        }
    },
    watch: {
        coordenadasExternas: {
            deep: true,
            handler(){
                if(this.desactivarMapa) this.dibujarMapa()
            }
        },
        'model.idm_pais'(pais){
            this.consultarEstados()
        },
        'model.idm_estado'(estado){
            this.consultarCiudades()
        },
    },
    methods: {
        toggle(model){
            this.$refs.editarDatos.toggle()
            this.getTipoTienda()
            this.consultarPaises()
            this.model = _.cloneDeep(model)
            this.coordenadas.lat = +this.model.latitud
            this.coordenadas.lng = +this.model.longitud
            this.consultarEstados()
            this.consultarCiudades()
            if (model.logo !== null){
                this.$refs.cropLogo.set_image(`${model.logo_firmado}`)
            } else {
                this.$refs.cropLogo.instanciaCrop.remove()
            }
            this.fileList = []
            if (model.nit_soporte_firmado){
                this.fileList.push({
                    name: 'Ajunto nit',
                    url: model.nit_soporte_firmado
                })
            }
        },
        abrirModalMapa(){
            this.$refs.modalMapaGoogle.toggle()
        },
        handleRemove(file, fileList){
            this.fileList = []
            this.$refs.adjuntos.clearFiles()
        },
        async agregar_archivos(file, fileList){
            let nombreMayor100Carecteres = file.name.length > 100
            let tipo = null
            let tamano = null
            if (file.raw.type === 'application/pdf'){
                tipo = 1
                tamano = 20
            } else if (file.raw.type.split('/').slice(0, 1).pop() === 'image'){
                tipo = 2
                tamano = 1000
            } else if (file.raw.type === 'video/mp4'){
                tipo = 3
                tamano = 20
            } else {
                tipo = 4
                tamano = 25
            }

            const limite = file.size / 1024 / 1024 <= tamano
            if (!limite){
                fileList.splice(fileList.indexOf(file), 1)
                await this.notificacion('No válido', 'El archivo no cumple los requisitos', 'warning')
                return
            }

            if (tipo == 3 || tipo == 4){
                fileList.splice(fileList.indexOf(file), 1)
                await this.notificacion('No válido', 'El archivo no cumple con la extensión requerida(pdf ó imagenes)', 'warning')
                return
            }

            if (nombreMayor100Carecteres){
                fileList.splice(fileList.indexOf(file), 1)
                await this.notificacion('Error', `¡El nombre del archivo ${file.name} excede los 100 caracteres!`, 'error')
                return
            }

            this.archivo.push(file.raw)
        },
        async updateDatos(){
            try {

                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                const archivo = this.$refs.cropLogo.instanciaCrop.dataBase64.output
                this.model.logo = archivo.image
                this.model.logo_mini = null
                this.model.tipo = 2

                if (this.archivo.length){
                    this.loading = true
                    let that = this
                    let ruta = `tiendas/${Aws.getHash()}.${this.archivo[0].name.split('.').pop()}`
                    let file = await Aws.getInstance().upload({
                        Key: ruta,
                        ContentType: this.archivo[0].type,
                        Body: this.archivo[0]
                    }).on('httpUploadProgress', uploadEvent => {
                        let porcentaje = Math.round(uploadEvent.loaded / uploadEvent.total * 100)
                        that.carga = porcentaje
                    }).promise()
                    this.model.nit_soporte = file.Key
                    this.loading = false
                    this.carga = 0
                }else{
                    if (this.fileList.length == 0){
                        this.model.nit_soporte = null
                    }
                }

                const {data} = await DatosTienda.updateDatos(this.model)
                this.notificacion('Mensaje', 'Editado exitosamente', 'success')
                this.$emit('actualizar')
                this.$refs.adjuntos.clearFiles()
                this.archivo = []
                this.$refs.editarDatos.toggle()
            } catch (e){
                this.error_catch(e)
            }
        },
        tomarPrimerRegistroArray(valores, objetivo){
            if (!_.isEmpty(valores)) this.model[objetivo] = valores[0].id
        },
        async getTipoTienda(){
            try {
                const {data} = await DatosTienda.getTipoTienda()
                this.tipos_tiendas = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async consultarPaises(){
            try {
                const {data} = await Localizaciones.consultarPaises(this.$usuario.proyecto.id)
                this.paises = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async consultarEstados(){
            try {
                const {data} = await Localizaciones.consultarEstados({
                    proyecto: this.$usuario.proyecto.id,
                    pais: this.model.idm_pais
                })
                this.estados = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async consultarCiudades(){
            try {
                const {data} = await Localizaciones.consultarCiudades({
                    proyecto: this.$usuario.proyecto.id,
                    estado: this.model.idm_estado
                })
                this.ciudades = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        actualizarCoordenadasCiudad({ latitud, longitud }){
            this.model.latitud = latitud
            this.model.longitud = longitud
        }
    }
}
</script>
