import axios from 'axios'

const API_PROYECTO_PREFIJO = 'proyecto'

export default {
	listarCiudades(){ return axios.get(`cupones/ciudades`)},
    consultarProyecto: () => axios(`${API_PROYECTO_PREFIJO}/datos-dominio`),
    consultarPaises: payload => axios(`${API_PROYECTO_PREFIJO}/${payload}/pais`),
    consultarEstados: payload => axios(`${API_PROYECTO_PREFIJO}/${payload.proyecto}/pais/${payload.pais}/estados`),
    consultarCiudades: payload => axios(`${API_PROYECTO_PREFIJO}/${payload.proyecto}/estado/${payload.estado}/ciudades`),
    consultarCiudadesEstados: payload => axios(`${API_PROYECTO_PREFIJO}/${payload.proyecto}/pais/${payload.pais}/ciudades`, { params: payload }),
}
